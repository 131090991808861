body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.theme-one {
  background: linear-gradient(to top, #30093a, #501062);
}
.item-purchase-banner{
  display: none;
}

label span{
  padding:5px;
  color:red;
}

.spinner-outer{
  display: flex;
  justify-content:center;
  align-items: center;
  height: 200px
}

.spinner{
    width: 2.5rem;
    height:2.5rem;
    border:0.4rem solid #f3f3f3;
    border-top: 0.3rem solid #662678;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

.collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.collapse.show {
  height: auto; /* Will dynamically adjust to content height */
}
.text-container {
  white-space: normal;         /* Allows text to wrap within the container */
  word-wrap: break-word;       /* Breaks long words to wrap within the container */
  word-break: break-word;      /* Breaks words at the boundary */
  overflow-wrap: break-word;   /* Ensures wrapping in modern browsers */
  text-overflow: ellipsis;     /* Adds ellipsis for overflowing text */
}

.about-container {
  max-width: 500px;             /* Set a maximum width for the "about" field */
  overflow: hidden;             /* Hide overflow */
  white-space: pre-wrap;        /* Allows the text to wrap */
  word-break: break-word;       /* Break long words at the boundary */
}

.active{
  background-color: #541a64
}
.active .nav-link{
  padding-bottom: 10px !important;
}
.active .nav-link .menu-title, .active .nav-link .menu-icon{
  color: #e5c532 !important;
}

.pagibutton {
  margin: 2px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line */
  gap: 5px; /* Space between buttons */
  padding: 10px;
}

.btn-group {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  justify-content: center;
}
