.logout-div {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  }
  
  .show {
    display: block;
  }
  
  .hide {
    display: none;
  }

  /* Container for centering the profile card */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

/* Profile card layout */
.profile-card {
  background-color: #fff;
  padding: 30px;
  margin: 20px 0;
}

.noticeboard-card {
  background-color: #f8f9fa; /* Light background for better contrast */
  border: 1px solid #dee2e6; /* Border for card */
  border-radius: 0.375rem; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
/* Header with estate name */
.profile-header h2 {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Flexbox for profile image and content alignment */
.profile-info {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}

/* Image section styling */
.image-section {
  flex: 1;
}

.profile-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Placeholder image styling */
.profile-image[src='/images/placeholder.png'] {
  opacity: 0.5;
  object-fit: cover;
}

/* About section under the image */
.about-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
}

.about-section h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #444;
}

.about-section p {
  font-size: 1rem;
  color: #666;
  white-space: pre-wrap;
  line-height: 1.6;
}

/* Details table styling */
.details-section {
  flex: 2;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.details-table td {
  padding: 15px;
  border: 1px solid #f1f1f1;
}

.detail-key {
  font-weight: bold;
  width: 30%;
  color: #555;
}

.detail-value {
  color: #777;
  word-wrap: break-word;
  font-size: 1rem;
}

/* Responsive design for mobile screens */
@media (max-width: 768px) {
  .profile-info {
    flex-direction: column;
  }

  .profile-header h2 {
    font-size: 2rem;
  }

  .details-table td {
    padding: 10px;
  }
}

.noticeboard-card {
  background-color: #ffffff; /* White background for the card */
  border: 1px solid #e0e0e0; /* Light border for the card */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 2rem; /* Padding for the content */
  display: flex; /* Flexbox for alignment */
}

.noticeboard-content {
  display: flex; /* Horizontal layout for image and text */
}

.noticeboard-image {
  flex: 1; /* Takes up 1 part of the flex */
  margin-right: 20px; /* Space between image and text */
}

.noticeboard-image img {
  max-width: 100%; /* Responsive image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 0.375rem; /* Rounded corners for image */
  border: 1px solid #e0e0e0; /* Light border around image */
}

.noticeboard-details {
  flex: 2; /* Takes up 2 parts of the flex */
}

.noticeboard-title {
  font-size: 2rem; /* Larger font for title */
  font-weight: 600; /* Bold title */
  margin-bottom: 0.5rem; /* Space below title */
  color: #333; /* Dark color for readability */
}

.noticeboard-subtitle {
  font-size: 1.5rem; /* Medium size for subtitle */
  font-weight: 500; /* Semi-bold subtitle */
  color: #666; /* Muted color for subtitle */
  margin-bottom: 1rem; /* Space below subtitle */
}

.noticeboard-brief, .noticeboard-message {
  margin-bottom: 1rem; /* Space below brief/message */
  font-size: 1rem; /* Standard font size for content */
  color: #444; /* Darker color for content */
}

.noticeboard-brief strong, .noticeboard-message strong {
  color: #000; /* Strong color for section headers */
}

.loading-spinner, .error-message {
  display: flex;
  justify-content: center; /* Center align content */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
  color: #666; /* Color for loading/error text */
}
.ck-content{
  height:160px;
}
.ck-content:focus{
  border: 1px solid #b11414;
}
.ck-powered-by{
  visibility: hidden;
}
.ck-file-dialog-button{
  visibility: hidden;
}
.nav-links a{
  color:#6c757d
}
.uploadbtn{
  width:60px;
  position: absolute;
  top: 110px;
  right: 30px;
}

fieldset {
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 20px;
}
legend {
  font-weight: bold;
  font-size: 18px;
}
.card-body h3{
  text-align: center;
}
form{
  margin-top: 30px;
}

/* Keep table responsive on smaller screens */
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Disable .table-responsive for full screens (large desktops) */
@media (min-width: 1200px) {
  .table-responsive {
      overflow-x: visible;
      -webkit-overflow-scrolling: auto;
  }
}

.collapsible-btn {
  background-color: #007bff;
  color: white;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.articles-content {
  padding: 10px;
  border: 1px solid #ddd;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top:20px;
}

.article-content {
  padding: 20px;
  
}

.table td, .table th {
  white-space: nowrap; /* Prevents text wrapping */
}
.articles-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.price-input, .price-input:focus{
  color:rgb(5, 118, 5);
  font-weight: 300;
  font-size: 14px;
  padding: 0px 20px;
  padding-left: 50px;
  height: 33px;
  
}
.price{
  color:rgb(5, 118, 5);
  font-weight: 500;
  font-size: 14px;
}
.article-title-btn:hover {
  background-color: #e2e2e2;
}

.detail-key{
  width:42%
}

.input-wrapper {
  position: relative; /* Needed for positioning the pseudo-element */
  display: inline-block; /* Ensures the wrapper fits the input */
}

.input-wrapper::before {
  content: "Ugx."; /* The content to be added before the input */
  position: absolute;
  left: 10px; /* Adjust this to position the content as needed */
  top: 50%;
  transform: translateY(-56%); /* Centers the content vertically */
  font-size: 15px; /* Style as needed */
  color:rgb(5, 118, 5);
}